.item-img-div {
  aspect-ratio : 1 / 1;
  width:100%;
}

.item-img-wrapper-lazy-load-image {
 display: unset !important;
}

.item-img {
  display: block;
  margin: auto;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
