.xvideos-content-block {
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
}

.item-img-wrapper-lazy-load-image {
 display: unset !important;
}

.item-img {
  display: block;
  margin: auto;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
